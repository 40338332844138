import React from "react";

interface propsTypes extends React.HTMLAttributes<HTMLButtonElement> {
  className: string;
  padding: string;
  img?: string;
  text: string;
  icon: React.ReactElement | string;
}

const Button = React.forwardRef(
  ({ className, padding, img, text, icon, ...props }: propsTypes, ref: any) => {
    return (
      <button
        ref={ref}
        {...props}
        className={`${className} ${padding} transition-all duration-300 font-medium flex items-center`}
      >
        {img && (
          <img
            className="h-auto w-auto 2xl:h-16 2xl:w-16"
            alt="userImg"
            src={img}
          />
        )}
        {text}
        {icon}
      </button>
    );
  }
);

export default Button;
