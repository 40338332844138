import React from "react";
import { RotatingLines } from "react-loader-spinner";

const SmallLoader = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <RotatingLines
        strokeColor="#FF7425"
        strokeWidth="5"
        animationDuration="0.75"
        width="30"
        visible={true}
      />
    </div>
  );
};

export default SmallLoader;
