import React, { createContext, useEffect, useState } from "react";
import { createCtx } from "./Context";
import toast from "react-hot-toast";
import { fetchRequest } from "../utils/axios";
import { REQ } from "../constant/api-endpoints";
import { HTTP_METHODS, HTTP_STATUS_CODES } from "../constant";
import {
  ContactUsInterface,
  FundFormInterface,
  FundInterface,
  GetPaginatedResponseInterface,
  InfluencerFormInterface,
  InfluencerInterface,
  InvestorFormInterface,
  InvestorInterface,
  UserFormInterface,
  UserInterface,
  FilterFormInterface,
  HeaderDataInterface,
} from "../interfaces";
import { useGetQuery } from "../store/store-hook";
import { getToken } from "../utils/get-token";

type PropsContextType = {
  usersData: GetPaginatedResponseInterface;
  influencerData: GetPaginatedResponseInterface;
  investorData: GetPaginatedResponseInterface;
  fundData: GetPaginatedResponseInterface;
  contactUsData: GetPaginatedResponseInterface;
  filterData: GetPaginatedResponseInterface;
  fundNamesData: { id: string; name: string }[];
  userPage: number;
  userLimit: number;
  influencerPage: number;
  influencerLimit: number;
  investorPage: number;
  investorLimit: number;
  fundPage: number;
  fundLimit: number;
  contactUsPage: number;
  contactUsLimit: number;
  filterPage: number;
  filterLimit: number;
  dataTableLoading: boolean;
  userDataLoading: boolean;
  influencerDataLoading: boolean;
  investorDataLoading: boolean;
  fundDataLoading: boolean;
  contactUsDataLoading: boolean;
  filterDataLoading: boolean;
  fundNamesDataLoading: boolean;
  filter: string;
  headerData: HeaderDataInterface;
  setUserPage: React.Dispatch<React.SetStateAction<number>>;
  setUserLimit: React.Dispatch<React.SetStateAction<number>>;
  setInfluencerPage: React.Dispatch<React.SetStateAction<number>>;
  setInfluencerLimit: React.Dispatch<React.SetStateAction<number>>;
  setInvestorPage: React.Dispatch<React.SetStateAction<number>>;
  setInvestorLimit: React.Dispatch<React.SetStateAction<number>>;
  setFundPage: React.Dispatch<React.SetStateAction<number>>;
  setFundLimit: React.Dispatch<React.SetStateAction<number>>;
  setContactUsPage: React.Dispatch<React.SetStateAction<number>>;
  setContactUsLimit: React.Dispatch<React.SetStateAction<number>>;
  setFilterPage: React.Dispatch<React.SetStateAction<number>>;
  setFilterLimit: React.Dispatch<React.SetStateAction<number>>;
  setDataTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderData: React.Dispatch<React.SetStateAction<HeaderDataInterface>>;
  onAddUser: (payload: UserFormInterface, callBack?: () => void) => void;
  onUpdateUser: (id: string, payload: UserInterface) => void;
  onDeleteUser: (id: string) => void;
  onAddInfluencer: (payload: InfluencerFormInterface) => void;
  onUpdateInfluencer: (id: string, payload: InfluencerInterface) => void;
  onDeleteInfluencer: (id: string) => void;
  onAddInvestor: (payload: InvestorFormInterface) => void;
  onUpdateInvestor: (id: string, payload: InvestorInterface) => void;
  onDeleteInvestor: (id: string) => void;
  onAddFund: (payload: FundFormInterface) => void;
  onAddFilters: (payload: FilterFormInterface) => void;
  onUpdateFilter: (id: string, payload: FilterFormInterface) => void;
  onUpdateFund: (id: string, payload: FundInterface) => void;
  onDeleteFund: (id: string) => void;
  onUpdateContactUs: (id: string, payload: ContactUsInterface) => void;
  refetchUsers: () => void;
  refetchInfluencer: () => void;
  refetchInvestor: () => void;
  refetchFund: () => void;
  refetchContactUs: () => void;
  refetchFilter: () => void;
  refetchFundNamesData: () => void;
};

export const [useProps, CtxProvider] = createCtx<PropsContextType>();

export const PropsContext = createContext<PropsContextType | undefined>(
  undefined
);

export const PropsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const token = getToken();

  const [dataTableLoading, setDataTableLoading] = useState<boolean>(false);
  const [userPage, setUserPage] = useState<number>(1);
  const [userLimit, setUserLimit] = useState<number>(10);

  const [influencerPage, setInfluencerPage] = useState<number>(1);
  const [influencerLimit, setInfluencerLimit] = useState<number>(10);

  const [investorPage, setInvestorPage] = useState<number>(1);
  const [investorLimit, setInvestorLimit] = useState<number>(10);

  const [fundPage, setFundPage] = useState<number>(1);
  const [fundLimit, setFundLimit] = useState<number>(10);

  const [contactUsPage, setContactUsPage] = useState<number>(1);
  const [contactUsLimit, setContactUsLimit] = useState<number>(10);

  const [filterPage, setFilterPage] = useState<number>(1);
  const [filterLimit, setFilterLimit] = useState<number>(10);
  const [filter, setFilter] = useState<any>("type");

  const [headerData, setHeaderData] = useState<HeaderDataInterface>({
    pageIcon: "",
    pageTitle: "",
    dataCount: 0,
  });

  const {
    data: usersData,
    isLoading: userDataLoading,
    refetch: refetchUsers,
  } = useGetQuery(
    `${REQ.USER.GET_ALL_PAGINATED}?page=${userPage}&limit=${userLimit}`,
    {
      skip: !token,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: influencerData,
    isLoading: influencerDataLoading,
    refetch: refetchInfluencer,
  } = useGetQuery(
    `${REQ.INFLUENCER.GET_ALL_PAGINATED}?page=${influencerPage}&limit=${influencerLimit}`,
    {
      skip: !token,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: investorData,
    isLoading: investorDataLoading,
    refetch: refetchInvestor,
  } = useGetQuery(
    `${REQ.INVESTOR.GET_ALL_PAGINATED}?page=${investorPage}&limit=${investorLimit}`,
    {
      skip: !token,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: fundData,
    isLoading: fundDataLoading,
    refetch: refetchFund,
  } = useGetQuery(
    `${REQ.FUND.GET_ALL_PAGINATED}?page=${fundPage}&limit=${fundLimit}`,
    {
      skip: !token,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: fundNamesData,
    isLoading: fundNamesDataLoading,
    refetch: refetchFundNamesData,
  } = useGetQuery(REQ.FUND.GET_NAME, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: contactUsData,
    isLoading: contactUsDataLoading,
    refetch: refetchContactUs,
  } = useGetQuery(
    `${REQ.CONTACT_US.GET_ALL_PAGINATED}?page=${contactUsPage}&limit=${contactUsLimit}`,
    {
      skip: !token,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: filterData,
    isLoading: filterDataLoading,
    refetch: refetchFilter,
  } = useGetQuery(
    `${REQ.FILTER.GET_ALL_PAGINATED}?name=${filter}&page=${filterPage}&limit=${filterLimit}`,
    {
      skip: !token,
      refetchOnMountOrArgChange: true,
    }
  );

  const onAddUser = async (
    payload: UserFormInterface,
    callBack?: () => void
  ) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.USER.CREATE,
        type: HTTP_METHODS.POST,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        callBack && callBack();
        toast.success("User created successfully");
        refetchUsers();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onUpdateUser = async (id: string, payload: UserInterface) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.USER.UPDATE.replace(":id", id),
        type: HTTP_METHODS.PATCH,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("User updated successfully");
        refetchUsers();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onDeleteUser = async (id: string) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.USER.UPDATE.replace(":id", id),
        type: HTTP_METHODS.DELETE,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("User deleted successfully");
        refetchUsers();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onAddInfluencer = async (payload: InfluencerFormInterface) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.INFLUENCER.CREATE,
        type: HTTP_METHODS.POST,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Influencer created successfully");
        refetchInfluencer();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onUpdateInfluencer = async (
    id: string,
    payload: InfluencerInterface
  ) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.INFLUENCER.UPDATE.replace(":id", id),
        type: HTTP_METHODS.PATCH,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Influencer updated successfully");
        refetchInfluencer();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onDeleteInfluencer = async (id: string) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.INFLUENCER.UPDATE.replace(":id", id),
        type: HTTP_METHODS.DELETE,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Influencer deleted successfully");
        refetchInfluencer();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onAddInvestor = async (payload: InvestorFormInterface) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.INVESTOR.CREATE,
        type: HTTP_METHODS.POST,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Investor created successfully");
        refetchInvestor();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onUpdateInvestor = async (id: string, payload: InvestorInterface) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.INVESTOR.UPDATE.replace(":id", id),
        type: HTTP_METHODS.PATCH,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Investor updated successfully");
        refetchInvestor();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onDeleteInvestor = async (id: string) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.INVESTOR.UPDATE.replace(":id", id),
        type: HTTP_METHODS.DELETE,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Investor deleted successfully");
        refetchInvestor();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onAddFund = async (payload: FundFormInterface) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.FUND.CREATE,
        type: HTTP_METHODS.POST,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Fund created successfully");
        refetchFund();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onUpdateFund = async (id: string, payload: FundInterface) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.FUND.UPDATE.replace(":id", id),
        type: HTTP_METHODS.PATCH,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Fund updated successfully");
        refetchFund();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onDeleteFund = async (id: string) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.FUND.UPDATE.replace(":id", id),
        type: HTTP_METHODS.DELETE,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Fund deleted successfully");
        refetchFund();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onUpdateContactUs = async (id: string, payload: ContactUsInterface) => {
    try {
      setDataTableLoading(true);

      const response = await fetchRequest({
        url: REQ.CONTACT_US.UPDATE.replace(":id", id),
        type: HTTP_METHODS.PATCH,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Status updated successfully");
        refetchContactUs();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onAddFilters = async (payload: FilterFormInterface) => {
    try {
      const response = await fetchRequest({
        url: REQ.FILTER.ADD_FILTER,
        type: HTTP_METHODS.POST,
        body: payload,
      });
      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Type added successfully");
        refetchFilter();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  const onUpdateFilter = async (id: string, payload: FilterFormInterface) => {
    try {
      const { name } = payload;

      setDataTableLoading(true);
      const response = await fetchRequest({
        url: REQ.FILTER.EDIT_FILTER.replace(":id", id),
        type: HTTP_METHODS.PATCH,
        body: payload,
      });
      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success(
          `${name.charAt(0).toUpperCase() + name.slice(1)} updated successfully`
        );
        refetchFilter();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDataTableLoading(false);
    }
  };

  return (
    <CtxProvider
      value={{
        usersData,
        influencerData,
        investorData,
        fundData,
        fundNamesData,
        contactUsData,
        filterData,
        userPage,
        userLimit,
        influencerPage,
        influencerLimit,
        investorPage,
        investorLimit,
        fundPage,
        fundLimit,
        contactUsPage,
        contactUsLimit,
        filterPage,
        filterLimit,
        headerData,
        dataTableLoading,
        userDataLoading,
        influencerDataLoading,
        investorDataLoading,
        fundDataLoading,
        fundNamesDataLoading,
        contactUsDataLoading,
        filterDataLoading,
        setUserPage,
        setUserLimit,
        setInfluencerPage,
        setInfluencerLimit,
        setInvestorPage,
        setInvestorLimit,
        setFundPage,
        setFundLimit,
        setContactUsPage,
        setContactUsLimit,
        setFilterPage,
        setFilterLimit,
        setDataTableLoading,
        setHeaderData,
        refetchUsers,
        refetchInfluencer,
        refetchInvestor,
        refetchFund,
        refetchFundNamesData,
        refetchContactUs,
        refetchFilter,
        onAddUser,
        onUpdateUser,
        onDeleteUser,
        onAddInfluencer,
        onUpdateInfluencer,
        onDeleteInfluencer,
        onAddInvestor,
        onUpdateInvestor,
        onDeleteInvestor,
        onAddFund,
        onAddFilters,
        onUpdateFilter,
        onUpdateFund,
        onDeleteFund,
        onUpdateContactUs,
        filter,
        setFilter,
      }}
    >
      {children}
    </CtxProvider>
  );
};

export default PropsProvider;
