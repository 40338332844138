import React from "react";
import ReactDOM from "react-dom/client";
import MainRouter from "./routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./styles/global.scss";
import { store } from "./store";
import { Toaster } from "react-hot-toast";
import PropsProvider from "./contexts/PropsContext";
import "primeicons/primeicons.css";
import { HomePropsProvider } from "./contexts/HomePropsContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <PropsProvider>
        <HomePropsProvider>
          <MainRouter />
        </HomePropsProvider>
      </PropsProvider>
    </BrowserRouter>
    <Toaster position="top-center" reverseOrder={false} />
  </Provider>
);
