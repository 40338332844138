import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum modalType {
  "none",
  "filters",
  "add_user",
  "add_influencer",
  "add_investor",
  "add_fund",
  "add_filters",
  "edit_user",
  "edit_influencer",
  "edit_investor",
  "edit_funds",
  "edit_filters",
  "delete_user",
  "section_one",
  "section_two",
  "section_three",
  "section_four",
  "section_five",
  "section_six",
  "section_six_card",
  "section_seven",
}

interface UiState {
  modal: modalType | false;
  modalState: unknown;
}

const initialState: UiState = {
  modal: false,
  modalState: null,
};

export const uiSlice = createSlice({
  name: "userInterface",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.modal = false;
      state.modalState = null;
    },
    updateModalAndState: (
      state,
      action: PayloadAction<{ type: modalType; state: unknown }>
    ) => {
      state.modal = action.payload.type;
      state.modalState = action.payload.state;
    },
  },
});

export const { closeModal, updateModalAndState } = uiSlice.actions;
export default uiSlice.reducer;
