import React from "react";
import InvestorsDetail from "../pages/InvestorsDetail";
import InfluencersDetail from "../pages/InfluencersDetail";
import FundsDetail from "../pages/FundsDetail";

const ChangePassword = React.lazy(() => import("../pages/ChangePassword"));
const ContactUs = React.lazy(() => import("../pages/ContactUs"));
const Funds = React.lazy(() => import("../pages/Funds"));
const Home = React.lazy(() => import("../pages/Home"));
const Influencers = React.lazy(() => import("../pages/Influencers"));
const Investors = React.lazy(() => import("../pages/Investors"));
const Users = React.lazy(() => import("../pages/Users"));
const Filter = React.lazy(() => import("../pages/Filter"));

export const ROUTES_CONSTANT = {
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  HOME: "/dashboard",
  USERS: "/dashboard/users",
  INFLUENCERS: "/dashboard/influencers",
  INFLUENCERS_DETAIL: "/dashboard/influencers-detail/:id",
  INVESTORS: "/dashboard/investors",
  INVESTORS_DETAIL: "/dashboard/investors-detail/:id",
  FUNDS: "/dashboard/funds",
  FUNDS_DETAIL: "/dashboard/funds-detail/:id",
  CONTACT_US: "/dashboard/contact-us",
  SETTING: "/dashboard/setting",
  FILTER: "/dashboard/filter",
  CHANGE_PASSWORD: "/dashboard/change-password",
};

export const ROUTE_LIST = [
  { path: ROUTES_CONSTANT.HOME, element: Home, isLayout: true },
  { path: ROUTES_CONSTANT.USERS, element: Users, isLayout: true },
  { path: ROUTES_CONSTANT.INFLUENCERS, element: Influencers, isLayout: true },
  {
    path: ROUTES_CONSTANT.INFLUENCERS_DETAIL,
    element: InfluencersDetail,
    isLayout: true,
  },
  { path: ROUTES_CONSTANT.INVESTORS, element: Investors, isLayout: true },
  {
    path: ROUTES_CONSTANT.INVESTORS_DETAIL,
    element: InvestorsDetail,
    isLayout: true,
  },
  { path: ROUTES_CONSTANT.FUNDS, element: Funds, isLayout: true },
  { path: ROUTES_CONSTANT.FUNDS_DETAIL, element: FundsDetail, isLayout: true },
  { path: ROUTES_CONSTANT.CONTACT_US, element: ContactUs, isLayout: true },
  { path: ROUTES_CONSTANT.FILTER, element: Filter, isLayout: true },
  {
    path: ROUTES_CONSTANT.CHANGE_PASSWORD,
    element: ChangePassword,
    isLayout: false,
  },
];

export const SIDE_NAVIGATION = {
  HOME: "Home",
  USERS: "Users",
  INFLUENCERS: "Influencers",
  INVESTORS: "Investors",
  FUNDS: "Funds",
  CONTACT_US: "Contact Us",
  FILTER: "Filter",
};

/* --- HTTP Request Methods --- */
export const HTTP_METHODS = {
  GET: "get",
  POST: "post",
  PATCH: "patch",
  DELETE: "delete",
};

/* --- HTTP Status Codes --- */
export const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  RESOURCE_NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export const ROLE_TYPE = {
  USER: "user",
  INFLUENCER: "influencer",
  INVESTOR: "investor",
  FUND: "fund",
};

export const CONTACT_US_OPTIONS = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Completed",
    value: "completed",
  },
];

export const FILTERS_SIDE_NAVIGATION = {
  TYPE: "type",
  INDUSTRY: "industry",
  GEOGRAPHY: "geography",
  STAGE: "stage",
  LOCATION: "location",
};
