export const REQ = {
  AUTH: {
    SIGNIN: "/auth/admin-signin",
    VERIFY: "/auth/verify-user",
    LOG_OUT: "/auth/logout",
    CHANGE_PASSWORD: "/user/change-password",
  },
  USER: {
    GET_ALL_PAGINATED: "/user/paginated",
    CREATE: "/user",
    UPDATE: "/user/:id",
    DELETE: "/user/:id",
  },
  INFLUENCER: {
    GET_ALL_PAGINATED: "/influencer/paginated",
    CREATE: "/influencer",
    UPDATE: "/influencer/:id",
    DELETE: "/influencer/:id",
    GET_BY_ID: "/influencer/:id",
  },
  INVESTOR: {
    GET_ALL_PAGINATED: "/investor/paginated",
    CREATE: "/investor",
    UPDATE: "/investor/:id",
    DELETE: "/investor/:id",
    GET_BY_ID: "/investor/:id",
  },
  FUND: {
    GET_ALL_PAGINATED: "/fund/paginated",
    CREATE: "/fund",
    GET_NAME: "/fund/names",
    UPDATE: "/fund/:id",
    DELETE: "/fund/:id",
    GET_BY_ID: "/fund/:id",
  },
  CONTACT_US: {
    GET_ALL_PAGINATED: "/contact-us/paginated",
    UPDATE: "/contact-us/:id",
  },
  STORE_HTML: {
    GET: "/store-html",
    UPDATE: "/store-html/:id",
  },
  FILTER: {
    GET_ALL_PAGINATED: "/filter/paginated",
    ADD_FILTER: "/filter",
    EDIT_FILTER: "/filter/:id",
  },
  UPLOAD_FILE: "/upload-file",
};
