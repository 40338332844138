import { ReactSelectInterface, StoreHtmlDataInterface } from "../interfaces";
import { UploadMedia } from "./upload-media";

export const ConvertArrayIntoObject = (
  array: StoreHtmlDataInterface[]
): StoreHtmlDataInterface => {
  return array.reduce((accumulator, currentObject) => {
    accumulator.id = currentObject.id;
    accumulator.sectionOne = currentObject.sectionOne;
    accumulator.sectionTwo = currentObject.sectionTwo;
    accumulator.sectionThree = currentObject.sectionThree;
    accumulator.sectionFour = currentObject.sectionFour;
    accumulator.sectionFive = currentObject.sectionFive;
    accumulator.sectionSix = currentObject.sectionSix;
    accumulator.sectionSeven = currentObject.sectionSeven;

    return accumulator;
  }, {} as StoreHtmlDataInterface);
};

export const UploadImage = async (
  image: File | null,
  callback: (res: string) => void
) => {
  if (!image) return "";

  const res: string = await UploadMedia(image);
  callback(res);
};

export const CapitalizeFirstLetter = (input: string | string[]) => {
  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  if (Array.isArray(input)) {
    return input.map((str) => {
      if (typeof str === "string") return capitalize(str);

      return str;
    });
  } else if (typeof input === "string") return capitalize(input);
  else return input;
};

export const ConvertArrayIntoSelectOptions = (
  arr: any[]
): ReactSelectInterface[] => {
  return arr.length > 0
    ? arr.map((item: any) => ({
        label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
        value: item.id,
      }))
    : [];
};

export const RemoveItem = (arr: any[], cond1: any): any[] => {
  return arr.filter((item: any) => item.id !== cond1);
};

export const GenerateRandomPassword = (): string => {
  const length = 10;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const GetUserBlockedStatus = (user: any): boolean => {
  return user.status === "blocked";
};
