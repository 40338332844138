import React from "react";
import Button from "../common/Button";
import { CiGlobe, CiLinkedin, CiMail, CiTwitter } from "react-icons/ci";
import {
  FundInterface,
  InfluencerInterface,
  InvestorInterface,
} from "../../interfaces";

const GetInTouch: React.FC<{
  data: InvestorInterface | InfluencerInterface | FundInterface;
}> = ({ data }) => {
  return (
    <div className="px-5 pt-5 pb-6 flex flex-col gap-3 w-full bg-black bg-opacity-90 rounded-xl">
      <h1 className="text-lg font-semibold text-white">Get in touch</h1>
      <div className="flex flex-wrap gap-y-5 gap-3 items-center">
        <Button
          padding="py-2 px-4"
          className="flex items-center gap-2 rounded-xl bg-blue-500 hover:bg-opacity-80 text-white font-medium"
          text="Website"
          icon={<CiGlobe className="h-6 w-6" />}
        />
        <Button
          padding="py-2 px-4"
          className="flex items-center gap-2 rounded-xl bg-white hover:bg-opacity-80 text-mainTextColor font-medium"
          text="Email"
          icon={<CiMail className="h-6 w-6 text-mainTextColor" />}
          onClick={() =>
            window.open(
              `mailto:${data.email}?subject=Subject&body=Body%20goes%20here`
            )
          }
        />
        <Button
          padding="py-2 px-2"
          className="flex items-center gap-2 rounded-lg bg-white hover:bg-opacity-80 font-medium"
          text=""
          icon={<CiLinkedin className="h-6 w-6" />}
          onClick={() => window.open(data.linkedin)}
        />
        <Button
          padding="py-2 px-2"
          className="flex items-center gap-2 rounded-lg bg-white hover:bg-opacity-80 font-medium"
          text=""
          icon={<CiTwitter className="h-6 w-6" />}
          onClick={() => window.open(data.twitter)}
        />
      </div>
    </div>
  );
};

export default GetInTouch;
