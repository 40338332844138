import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ROUTES_CONSTANT, ROUTE_LIST } from "../constant";
import ScrollToTop from "../hooks/ScrollToTop";
import ScreenLoader from "../components/Loaders/ScreenLoader";
import PublicRoute from "./Public";
import PrivateRoute from "./Private";

const SignIn = React.lazy(() => import("../pages/SignIn"));
const MainLayout = React.lazy(() => import("../Layout"));

const MainRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.document.title = `${
      pathname.includes(`${ROUTES_CONSTANT.HOME}/`)
        ? pathname
            .replace(ROUTES_CONSTANT.HOME + "/", "")
            .replace("-", " ")
            .toUpperCase()
        : pathname.replace("/", "").replace("-", " ").toUpperCase()
    } | Dashboard | Raiderly`;
  }, [pathname]);

  return (
    <>
      <Suspense fallback={<ScreenLoader />}>
        <ScrollToTop />

        <Routes>
          {/* for Public Route */}
          <Route element={<PublicRoute />}>
            <Route path={ROUTES_CONSTANT.SIGN_IN} element={<SignIn />} />
          </Route>
          {/* for Private Route  */}
          <Route element={<PrivateRoute />}>
            {ROUTE_LIST.map(({ path, element: Component, isLayout }, i) =>
              isLayout ? (
                <Route
                  key={"routes---" + i}
                  path={path}
                  element={
                    <MainLayout>
                      <Component />
                    </MainLayout>
                  }
                />
              ) : (
                <Route
                  key={"routes---" + i}
                  path={path}
                  element={<Component />}
                />
              )
            )}
          </Route>

          <Route
            path="*"
            element={<Navigate to={ROUTES_CONSTANT.HOME} replace />}
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default MainRouter;
