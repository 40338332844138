import React, { createContext, useEffect, useState } from "react";
import { createCtx } from "./Context";
import toast from "react-hot-toast";
import { REQ } from "../constant/api-endpoints";
import { SectionsDataInterface, StoreHtmlDataInterface } from "../interfaces";
import { useGetQuery } from "../store/store-hook";
import { ConvertArrayIntoObject } from "../utils/functions";
import { HTTP_METHODS, HTTP_STATUS_CODES } from "../constant";
import { fetchRequest } from "../utils/axios";
import { getToken } from "../utils/get-token";
import {
  SectionFiveData,
  SectionFourData,
  SectionOneData,
  SectionSevenData,
  SectionSixData,
  SectionThreeData,
  SectionTwoData,
} from "./data";

type HomePropsContextType = {
  storeHtmlObj: StoreHtmlDataInterface;
  storeHtmlDataLoading: boolean;
  sectionsData: SectionsDataInterface;
  setSectionsData: React.Dispatch<
    React.SetStateAction<SectionsDataInterface | any>
  >;
  setStoreHtmlObj: React.Dispatch<
    React.SetStateAction<StoreHtmlDataInterface | any>
  >;
  refetchStoreHtml: () => void;
  onUpdateStoreHtml: (id: string, payload: StoreHtmlDataInterface) => void;
};

export const [useHomeProps, CtxProvider] = createCtx<HomePropsContextType>();

export const HomePropsContext = createContext<HomePropsContextType | undefined>(
  undefined
);

export const HomePropsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [storeHtmlObj, setStoreHtmlObj] = useState<
    StoreHtmlDataInterface | any
  >();

  const [sectionsData, setSectionsData] = useState<SectionsDataInterface>({
    sectionOne: SectionOneData,
    sectionTwo: SectionTwoData,
    sectionThree: SectionThreeData,
    sectionFour: SectionFourData,
    sectionFive: SectionFiveData,
    sectionSix: SectionSixData,
    sectionSeven: SectionSevenData,
  });

  const {
    data: storeHtmlData,
    isLoading: storeHtmlDataLoading,
    refetch: refetchStoreHtml,
  } = useGetQuery(`${REQ.STORE_HTML.GET}`, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (storeHtmlData && storeHtmlData.length > 0) {
      const data: StoreHtmlDataInterface = ConvertArrayIntoObject(
        storeHtmlData as StoreHtmlDataInterface[]
      );

      setStoreHtmlObj(data);
      setSectionsData({
        sectionOne: data.sectionOne,
        sectionTwo: data.sectionTwo,
        sectionThree: data.sectionThree,
        sectionFour: data.sectionFour,
        sectionFive: data.sectionFive,
        sectionSix: data.sectionSix,
        sectionSeven: data.sectionSeven,
      });
    }
  }, [storeHtmlData]);

  const onUpdateStoreHtml = async (
    id: string,
    payload: StoreHtmlDataInterface
  ) => {
    try {
      const response = await fetchRequest({
        url: REQ.STORE_HTML.UPDATE.replace(":id", id),
        type: HTTP_METHODS.PATCH,
        body: payload,
      });

      if (response.status === HTTP_STATUS_CODES.OK) {
        toast.success("Html updated successfully");
        refetchStoreHtml();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <CtxProvider
      value={{
        storeHtmlObj,
        storeHtmlDataLoading,
        sectionsData,
        setSectionsData,
        setStoreHtmlObj,
        refetchStoreHtml,
        onUpdateStoreHtml,
      }}
    >
      {children}
    </CtxProvider>
  );
};

export default HomePropsProvider;
