import React, { useEffect } from "react";
import { MdKeyboardArrowDown, MdVerified } from "react-icons/md";
import { IoMail } from "react-icons/io5";
import Tag from "../../components/InvestorsDetail/Tag";
import GetInTouch from "../../components/InvestorsDetail/GetInTouch";
import { useNavigate, useParams } from "react-router-dom";
import { useGetQuery } from "../../store/store-hook";
import { REQ } from "../../constant/api-endpoints";
import SmallLoader from "../../components/Loaders/SmallLoader";
import { ROLE_TYPE, ROUTES_CONSTANT } from "../../constant";
import { useProps } from "../../contexts/PropsContext";

const InvestorsDetail = () => {
  const { id: investorId } = useParams();

  const { setHeaderData } = useProps();

  const navigate = useNavigate();

  const { data: investorData, isLoading } = useGetQuery(
    REQ.INVESTOR.GET_BY_ID.replace(":id", investorId ?? ""),
    {
      skip: !investorId,
      refetchOnMountOrArgChange: true,
    }
  );

  const onNavigateDetailPage = (id: string) => {
    navigate(ROUTES_CONSTANT.FUNDS_DETAIL.replace(":id", id));
  };

  useEffect(() => {
    if (investorData) {
      setHeaderData({
        pageTitle: investorData.name,
      });
    }

    return () => {
      setHeaderData({
        pageTitle: "",
        pageIcon: "",
        dataCount: 0,
      });
    };
  }, [investorData]);

  return (
    <>
      {isLoading && !investorData ? (
        <SmallLoader />
      ) : (
        <div className="hidden md:flex justify-end ">
          <div className="w-full flex flex-col lg:flex-row justify-between gap-8 mb-8 px-3">
            <div className="w-full lg:w-7/12">
              <div className="flex flex-col gap-6 mb-8">
                <img
                  className="h-32 w-32 rounded-full"
                  src={
                    investorData.avatar || "/images/AllInvestors/userImg4.svg"
                  }
                  alt=""
                />
                <div className="flex flex-col gap-3">
                  <h1 className="flex items-center gap-2 capitalize font-semibold text-2xl text-mainTextColor">
                    {investorData.name}{" "}
                    {investorData.isVerified && (
                      <MdVerified className="h-7 w-7 text-green-600" />
                    )}
                  </h1>
                  <h2 className="text-gray-500 capitalize font-semibold text-lg">
                    {investorData.jobTitle}
                  </h2>
                </div>
              </div>
              <div className="flex flex-col gap-8 mb-8">
                {/* <UpgradeBasicPlan /> */}
                <GetInTouch data={investorData} />
              </div>
              <div className="flex flex-col gap-8">
                <h1 className="font-semibold text-mainTextColor text-2xl">
                  Details
                </h1>
                <div className="flex flex-col gap-2">
                  <h1 className="text-grayColor">Description</h1>
                  <p className="font-semibold text-mainTextColor">
                    {investorData.description}
                  </p>
                </div>
                {/* <div className="flex flex-col gap-2">
                <h1 className="text-grayColor">Location</h1>
                <p className="font-semibold text-mainTextColor flex items-center gap-2">
                  <FaMapPin className="text-red-500" /> San Francisco,
                  California, United States
                </p>
              </div> */}
                <div className="flex flex-col gap-2">
                  <h1 className="text-grayColor">Email address</h1>
                  <p
                    onClick={() =>
                      window.open(
                        `mailto:${investorData.email}?subject=Subject&body=Body%20goes%20here`
                      )
                    }
                    className="font-semibold text-mainTextColor hover:text-blue-500 transition-all duration-300 flex cursor-pointer items-center gap-2"
                  >
                    <IoMail className="text-black" /> {investorData.email}
                  </p>
                </div>
                {investorData.geography &&
                  investorData.geography.length > 0 && (
                    <div className="flex flex-col gap-2">
                      <h1 className="text-grayColor">Invest in regions</h1>
                      <div className="flex items-center gap-2 flex-wrap">
                        {investorData.geography.map(
                          (geography: string, index: string) => (
                            <Tag key={index} text={geography} />
                          )
                        )}
                      </div>
                    </div>
                  )}
                {investorData.stages && investorData.stages.length > 0 && (
                  <div className="flex flex-col gap-2">
                    <h1 className="text-grayColor">Invest in stages</h1>
                    <div className="flex items-center gap-2 flex-wrap">
                      {investorData.stages.map(
                        (stage: string, index: string) => (
                          <Tag key={index} text={stage} />
                        )
                      )}
                    </div>
                  </div>
                )}
                {investorData.industries &&
                  investorData.industries.length > 0 && (
                    <div className="flex flex-col gap-2">
                      <h1 className="text-grayColor">Invest in industries</h1>
                      <div className="flex items-center gap-2 flex-wrap">
                        {investorData.industries.map(
                          (industry: string, index: string) => (
                            <Tag key={index} text={industry} />
                          )
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="w-full lg:w-1/3 flex flex-col gap-1">
              <h1 className="font-semibold text-2xl text-mainTextColor">
                Fund
              </h1>
              <div className="pl-2 pt-4 pb-6 border-b mb-4">
                <h1
                  onClick={() =>
                    onNavigateDetailPage(investorData.organization.id)
                  }
                  className="flex items-center gap-5 transition-all duration-300 cursor-pointer hover:text-blue-500 font-semibold"
                >
                  <img
                    className="h-12 w-12 rounded-full"
                    src={investorData.organization.avatar}
                  />
                  {investorData.organization.name}
                </h1>
              </div>
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-1">
                  <h1 className="text-grayColor">Founded</h1>
                  <p className="text-2xl text-mainTextColor font-semibold">
                    {investorData.organization.founded}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <h1 className="text-grayColor">Investments</h1>
                  <p className="text-2xl text-mainTextColor font-semibold">
                    {investorData.organization.investments}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <h1 className="text-grayColor">Lead investments</h1>
                  <p className="text-2xl text-mainTextColor font-semibold">
                    {investorData.organization.leadInvestments}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <h1 className="text-grayColor">Exits</h1>
                  <p className="text-2xl text-mainTextColor font-semibold">
                    {investorData.organization.exits}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvestorsDetail;
