import { createSlice } from "@reduxjs/toolkit";
import { verifyUser } from "../action/user-verify";
import { AuthStateInterface } from "../../interfaces";

export const initialState: AuthStateInterface = {
  user: {
    id: "",
    googleId: "",
    username: "",
    email: "",
    avatar: "",
    password: "",
    status: "in-active",
    role: "",
    subscription: "",
    vipStatus: false,
    phone_number: "",
    jobTitle: "",
    website: "",
    sms_otp: "",
    sms_otp_created_at: "",
    sms_otp_expires_at: "",
    number_verified_at: "",
    createdAt: "",
    updatedAt: "",
  },
  isAuthenticated: false,
  isLoading: false,
  error: null,
};

const authUserInfo = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state: AuthStateInterface, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
    },
    logout: (state: AuthStateInterface) => {
      state.isAuthenticated = false;
    },
    loggedIn: (state: AuthStateInterface) => {
      state.isAuthenticated = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state: AuthStateInterface, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload.data.user;
        state.error = null;
      })
      .addCase(verifyUser.rejected, (state: AuthStateInterface, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export const { updateUser, logout, loggedIn } = authUserInfo.actions;
export default authUserInfo.reducer;
